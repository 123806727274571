"use client"

import React, { ReactNode } from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconClose } from "@supernovaio/icons"

import * as SheetPrimitive from "@radix-ui/react-dialog"

import { DMButtonWhite } from "../DMButtons"

import { cva, type VariantProps } from "class-variance-authority"
import { ActionBar } from "reshaped/bundle"

const DMSheet = SheetPrimitive.Root

function DMSheetPortal({ ...props }: SheetPrimitive.DialogPortalProps) {
  return <SheetPrimitive.Portal {...props} />
}

DMSheetPortal.displayName = SheetPrimitive.Portal.displayName

const sheetVariants = cva(
  "fixed z-30 gap-4 bg-elevation-base p-6 shadow-overlay",
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom:
          "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
        left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left",
        right:
          "inset-y-0 right-0 h-full w-3/4 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right",
      },
    },
    defaultVariants: {
      side: "right",
    },
  }
)

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {
  closesOnEscape?: boolean
  onClose?: () => void
}

const DMSheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(
  (
    {
      side = "right",
      className,
      children,
      onClose,
      closesOnEscape = false,
      ...props
    },
    ref
  ) => (
    <DMSheetPortal>
      <SheetPrimitive.Content
        ref={ref}
        className={cn(sheetVariants({ side }), className)}
        onOpenAutoFocus={(e) => {
          e.preventDefault()
        }}
        onEscapeKeyDown={(e) => {
          if (!closesOnEscape) {
            e.preventDefault()
            return
          }

          onClose?.()
        }}
        {...props}
      >
        {children}
        <SheetPrimitive.Close asChild className="absolute right-12 top-12">
          <DMButtonWhite icon={IconClose} size="small" onClick={onClose} />
        </SheetPrimitive.Close>
      </SheetPrimitive.Content>
    </DMSheetPortal>
  )
)

DMSheetContent.displayName = SheetPrimitive.Content.displayName

function DMSheetHeader({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={cn("flex flex-col", className)} {...props} />
}

DMSheetHeader.displayName = "SheetHeader"

function DMSheetFooter({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={cn("flex flex-col-reverse", className)} {...props} />
}

DMSheetFooter.displayName = "SheetFooter"

const DMSheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn("text-neutral text-title-4 hidden font-semibold", className)}
    {...props}
  />
))

DMSheetTitle.displayName = SheetPrimitive.Title.displayName

export type DMDrawerProps = {
  title: string
  children: ReactNode
  footer?: ReactNode
  isOpen?: boolean
  closesOnEscape?: boolean
  onClose?: () => void
  onOpenChange?: (open: boolean) => void
}

export function DMDrawer({
  title,
  children,
  footer,
  isOpen,
  closesOnEscape,
  onClose,
  onOpenChange,
}: DMDrawerProps) {
  return (
    // NOTE: modal={false}: causes background to be interactible
    <DMSheet modal={false} open={isOpen} onOpenChange={onOpenChange}>
      <DMSheetContent
        className="h-full w-[483px] overflow-y-auto"
        closesOnEscape={closesOnEscape}
        onClose={onClose}
      >
        <div className="flex h-full flex-col">
          <DMSheetHeader>
            <DMSheetTitle>{title}</DMSheetTitle>
          </DMSheetHeader>
          {children}
          {footer && (
            <div>
              <div>
                <ActionBar elevated>{footer}</ActionBar>
              </div>
            </div>
          )}
        </div>
      </DMSheetContent>
    </DMSheet>
  )
}
