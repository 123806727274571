"use client"

import * as React from "react"

import { DMDimensionInput, DMDimensionInputProps } from "@supernovaio/dm"

import { ToggleButtonUnitEnum } from "../DMUnitToggleButton"

export type DMOpacityInputProps = Pick<
  DMDimensionInputProps,
  | "name"
  | "className"
  | "id"
  | "step"
  | "isDisabled"
  | "isReadOnly"
  | "hasError"
  | "preventEnterSubmit"
  | "canReturnNull"
  | "placeholder"
> & {
  value: number | null
  onValueChange: (value: number | null) => void
}

/** A special version of `DMDimensionInput` that accepts and returns a normalised value [0-1],
 *  but displays a denormalised variant [0-100]. */
const DMOpacityInput = React.forwardRef<HTMLInputElement, DMOpacityInputProps>(
  ({ value, onValueChange, ...restProps }, ref) => {
    // Ensure denormalization rounds off any precision errors
    const denormalisedMeasure = value !== null ? Math.round(value * 100) : null

    const handleNumberChange = (value: number | null) => {
      let normalisedNewMeasure: number | null = null

      if (value !== null) {
        // Apply rounding after normalization to limit precision errors
        normalisedNewMeasure = Math.round((value / 100) * 100) / 100
      }

      onValueChange(normalisedNewMeasure)
    }

    return (
      <DMDimensionInput
        ref={ref}
        {...restProps}
        allowedUnits={[ToggleButtonUnitEnum["%"]]}
        max={100}
        min={0}
        value={{ measure: denormalisedMeasure, unit: "Percent" }}
        onDimensionValueChange={(value) => handleNumberChange(value.measure)}
      />
    )
  }
)

DMOpacityInput.displayName = "DMOpacityInput"

export { DMOpacityInput }
