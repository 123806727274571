"use client"

import * as React from "react"
import { useCallback, useState } from "react"

import { Input, InputProps } from "@supernovaio/dm/src/components/primitives"
import { normalizeTestId } from "@supernovaio/dm/src/utils/normalizeTestId"

export type DMNumberInputProps = Pick<
  InputProps,
  | "name"
  | "className"
  | "inputClassName"
  | "size"
  | "id"
  | "step"
  | "endSlot"
  | "hasError"
  | "placeholder"
  | "selectOnFocus"
  | "preventEnterSubmit"
  | "onChange"
  | "hideOutline"
  | "autoFocus"
  | "onBlur"
  | "onKeyDown"
> & {
  value: number | null
  onValueChange: (value: number | null) => void
  min?: number | null
  max?: number | null
  isDisabled?: InputProps["disabled"]
  isReadOnly?: InputProps["readOnly"]
  /* If true, the component will return null through `onValueChange` when the input cannot be parsed (for example, "0." or "e2").
   * When false, the component will return the previous number value or 0. */
  canReturnNull?: boolean
  dataTestId?: string
}

const DMNumberInput = React.forwardRef<HTMLInputElement, DMNumberInputProps>(
  (
    {
      value,
      onValueChange,
      isDisabled,
      isReadOnly,
      onChange,
      min,
      max,
      canReturnNull = true,
      dataTestId,
      ...restProps
    },
    ref
  ) => {
    const effectiveMin = min ?? Number.MIN_SAFE_INTEGER
    const effectiveMax = max ?? Number.MAX_SAFE_INTEGER

    if (effectiveMin > effectiveMax) {
      throw new Error("DMNumberInput: min cannot be greater than max")
    }

    const propsValue = clamp({
      value,
      min: effectiveMin,
      max: effectiveMax,
    })

    // Internal value which is used for input
    const [rawValue, setRawValue] = useState<string>(
      propsValue?.toString() ?? ""
    )

    // Number value (obtained from `rawValue`) which was returned to the parent through `onValueChange` last time
    const [lastReturnedNumberValue, setLastReturnedNumberValue] = useState<
      number | null
    >(propsValue)

    if (propsValue !== lastReturnedNumberValue) {
      // A different value is coming from the parent - force update of the inner state.
      // Raw value is not always convertible to number value without losing the input (consider "0.", "." and others).
      // If we'd do the operation below unconditionally, we would lose the input in such cases.
      setRawValue(propsValue?.toString() ?? "")
      setLastReturnedNumberValue(propsValue)
    }

    const memoizedOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const stringValue = event.target.value
        // It doesn't conform with i18n principles, but is okay for now
        const sanitizedStringValue = stringValue.trim().replace(",", ".")
        let effectiveStringValue = stringValue
        let numberValue = parseFloatStrict(sanitizedStringValue)

        if (!canReturnNull) {
          numberValue = numberValue ?? lastReturnedNumberValue ?? 0
        }

        const effectiveNumberValue = clamp({
          value: numberValue,
          min: effectiveMin,
          max: effectiveMax,
        })

        if (
          effectiveNumberValue !== null &&
          effectiveNumberValue !== numberValue
        ) {
          // Update the internal raw value if the number value got clamped, so they stay in sync
          effectiveStringValue = effectiveNumberValue.toString()
        }

        setRawValue(effectiveStringValue)
        setLastReturnedNumberValue(effectiveNumberValue)

        if (lastReturnedNumberValue !== effectiveNumberValue) {
          // Propagate the value only when it actually changed
          onValueChange(effectiveNumberValue)
        }

        onChange?.(event)
      },
      [
        effectiveMin,
        effectiveMax,
        onChange,
        onValueChange,
        lastReturnedNumberValue,
        canReturnNull,
      ]
    )

    return (
      <Input
        {...restProps}
        ref={ref}
        disabled={isDisabled}
        max={effectiveMax}
        min={effectiveMin}
        readOnly={isReadOnly}
        type="number"
        value={rawValue}
        onChange={memoizedOnChange}
        data-test-id={normalizeTestId(dataTestId)}
      />
    )
  }
)

DMNumberInput.displayName = "DMNumberInput"

export { DMNumberInput }

/* Function which converts string to number only when the conversion can be completed for the whole sequence.
  It differs from `Number()` and other less restrictive build-in solutions,
  because they can convert strings like "123e" to 123 or "0." to 0 */
function parseFloatStrict(str: string): number | null {
  if (/^([-+])?([0-9]+(\.[0-9]+)?|\.[0-9]+)$/.test(str)) {
    return Number(str)
  }

  return null
}

function clamp({
  value,
  min,
  max,
}: {
  value: number | null
  min: number
  max: number
}): number | null {
  if (value === null || !Number.isFinite(value)) {
    return null
  }

  return Math.min(Math.max(value, min), max)
}
