import React from "react"

import {
  DimensionTokenMetadata,
  ResolvedStyle,
} from "../../DMTokenPreview.types"
import { meetsMinimumFontSizeRequirements } from "../token-utils"

import { ThemeIcon } from "./ThemeIcon"

export type TextTileProps = Pick<
  ResolvedStyle,
  | "fontSize"
  | "fontWeight"
  | "fontFamily"
  | "letterSpacing"
  | "textTransform"
  | "textDecoration"
> & {
  fontSizeValue?: DimensionTokenMetadata | null | undefined
  cssValue?: string
  displayText?: string
  themes?: string[]
}

export function TextTile(props: TextTileProps) {
  const {
    fontSize,
    fontFamily,
    fontWeight,
    fontSizeValue,
    letterSpacing,
    textDecoration,
    textTransform,
    cssValue,
    displayText,
    themes,
  } = props

  // This conversion is needed to bypass the type check
  let textTransformValue: "uppercase" | "lowercase" | "capitalize" | undefined

  switch (textTransform) {
    case "uppercase":
      textTransformValue = "uppercase"
      break
    case "lowercase":
      textTransformValue = "lowercase"
      break
    case "capitalize":
      textTransformValue = "capitalize"
      break
    default:
      textTransformValue = undefined
  }

  const constructedCSS = {
    fontSize: fontSizeValue
      ? meetsMinimumFontSizeRequirements(
          fontSizeValue.value,
          fontSizeValue.unit,
          26
        )
        ? fontSize
        : "26px"
      : "14px",
    fontFamily: fontFamily || "Inter",
    fontWeight: fontWeight || "400",
    fontVariant:
      (textTransform?.toLowerCase() === "smallcaps" && "small-caps") ||
      undefined,
    letterSpacing: letterSpacing || undefined,
    textTransform: textTransformValue,
    textDecoration:
      (textDecoration &&
        (textDecoration === "strikethrough"
          ? "line-through"
          : textDecoration)) ||
      undefined,
  }

  return (
    <div className="relative flex h-full w-full items-center justify-center">
      <div
        className="preview-tile text-neutral w-full select-none truncate rounded px-12 py-4 text-center leading-[1]"
        style={constructedCSS}
      >
        {displayText || cssValue || "Ag"}
      </div>
      {themes && <ThemeIcon themes={themes} contrastingColor="dark" />}
    </div>
  )
}
