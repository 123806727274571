import * as React from "react"
import { SVGProps } from "react"

export const IconEditCrossed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="m6.667 6.667-4 4v2.666h2.666l4-4m1.327-1.326 1.67-1.67a1.886 1.886 0 0 0-2.667-2.666L7.996 5.337M9 4.333 11.667 7M2 2l12 12"
    />
  </svg>
)
