import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconHide, IconShow } from "@supernovaio/icons"

import { DMIcon } from "../../../DMIcon"
import { ResolvedStyle } from "../../DMTokenPreview.types"
import { contrastColor } from "../token-utils"

import { ThemeIcon } from "./ThemeIcon"

export type InnerBlockTileProps = Pick<
  ResolvedStyle,
  | "borderStyle"
  | "borderWidth"
  | "borderColor"
  | "borderRadius"
  | "boxShadow"
  | "opacity"
  | "background"
  | "blur"
> & {
  displayText?: string
  themes?: string[]
}

export function InnerBlockTile(props: InnerBlockTileProps) {
  const {
    borderStyle,
    borderWidth,
    borderColor,
    borderRadius,
    boxShadow,
    background,
    blur,
    opacity,
    displayText,
    themes,
  } = props

  // todo: add some visualizations when a border width is 0

  const constructedCSS = {
    borderStyle: borderStyle || "solid",
    borderWidth: borderWidth || "1px",
    borderColor: !boxShadow ? borderColor || "#000000" : undefined,
    borderRadius: borderRadius || undefined,
    boxShadow: boxShadow || undefined,
    opacity: opacity || undefined,
    background: background || undefined,
  }

  return (
    <div className="flex h-full w-full items-center justify-center p-8 relative">
      {
        blur && <style>{`.blur${blur} {  filter: blur(${blur}); }`}</style> // workaround for React not supporting filter in style prop
      }
      <div
        className={cn(
          "preview-tile",
          "text-body-small text-neutral bg-elevation-base border-neutral-faded flex h-full max-h-[80px] w-full max-w-[160px] items-center justify-center gap-4 rounded",
          blur && `blur${blur}`
        )}
        style={constructedCSS}
      >
        {displayText === "Visible" || opacity === "Fully visible" ? (
          <DMIcon svg={IconShow} />
        ) : displayText === "Hidden" || displayText === "Fully transparent" ? (
          <DMIcon svg={IconHide} />
        ) : null}
        {displayText}
      </div>
      {themes && (
        <ThemeIcon
          themes={themes}
          contrastingColor={contrastColor(background)}
        />
      )}
    </div>
  )
}
