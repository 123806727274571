"use client"

import React, { InputHTMLAttributes, useEffect, useState } from "react"
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { cva, VariantProps } from "class-variance-authority"

const textareaVariants = cva([""], {
  variants: {
    size: {
      default: "",
      small: "h-[16px] text-body-small",
      medium: "h-[20px] text-body",
    },
  },
})

const removeBreakLines = <
  T extends number | string | readonly string[] | undefined
>(
  someString: T
) => {
  if (typeof someString === "string") {
    return someString.replace(/(\r\n|\n|\r)/gm, "")
  }

  return someString
}

export type TextAreaBaseProps = Omit<
  InputHTMLAttributes<HTMLTextAreaElement>,
  "size" | "height"
> &
  VariantProps<typeof textareaVariants> & {
    allowLineBreaks?: boolean
  } & TextareaAutosizeProps

/**
 * @deprecated This component is only being used in one place (DMInlineTextEdit)
 * The size prop isn't being passed to this component, while some styles are altered in the upper component.
 */
export const TextAreaBase = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaBaseProps
>(
  (
    {
      allowLineBreaks = true,
      className,
      style,
      value,
      size,
      onChange,
      ...props
    },
    ref
  ) => {
    const normalizedStyle = {
      ...style,
      resize: "none",
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [mounted, setMounted] = useState(false)

    // This is required, so it automatically recalculates the height of the textarea for async loaded fonts
    useEffect(() => {
      setMounted(true)
    }, [])

    const handleOnChange = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!onChange) {
          return
        }

        const { value } = event.currentTarget

        if (!allowLineBreaks) {
          const valueWithoutLineBreaks = removeBreakLines(value)

          // NOTE: when line breaks are disallowed, make sure that there are no line breaks there at all
          event.currentTarget.value = valueWithoutLineBreaks
          event.target.value = valueWithoutLineBreaks
        }

        onChange(event)
      },
      [allowLineBreaks, onChange]
    )

    return (
      <TextareaAutosize
        ref={ref}
        className={cn(
          textareaVariants({ size }),
          "w-full bg-[transparent] scrollbar-fancy",
          "disabled:cursor-not-allowed",
          "placeholder:text-neutral-placeholder",
          "focus:outline-none",
          className
        )}
        value={allowLineBreaks ? value : removeBreakLines(value)}
        onChange={handleOnChange}
        {...props}
        // @ts-expect-error: wrong typing of style.height, should be string | number | undefined, is just number | undefined
        style={normalizedStyle}
      />
    )
  }
)

TextAreaBase.displayName = "TextArea"
