import * as React from "react"
import { SVGProps } from "react"

export const IconExternal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M7.333 4.667H4A1.333 1.333 0 0 0 2.667 6v6A1.333 1.333 0 0 0 4 13.333h6A1.334 1.334 0 0 0 11.333 12V8.667m-4.666.666 6.666-6.666m0 0H10m3.333 0V6"
    />
  </svg>
)
