export type SwitchProps = {
  id: string
  isLabelRight?: boolean
  size?: "small" | "medium"
  isDisabled?: boolean
  isChecked?: boolean
  onChange?: (checked: boolean) => void
}

export type Label = {
  yes: string
  no: string
}

export type MixedLabel = (Label & { mixed: string }) | boolean

type DefaultLabel = Label | MixedLabel

const defaultLabel: DefaultLabel = {
  yes: "Yes",
  no: "No",
  mixed: "Mixed",
}

export const getLabel = ({
  label,
  isChecked,
}: {
  label?: Label | boolean
  isChecked?: boolean
}) => {
  if (label === true) return isChecked ? defaultLabel.yes : defaultLabel.no
  if (!label) return undefined
  return isChecked ? label.yes : label.no
}

export const getMixedLabel = (label?: MixedLabel) => {
  if (label === true) return defaultLabel.mixed
  if (label && "mixed" in label) return label.mixed
  return undefined
}
