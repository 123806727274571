import * as React from "react"

import Link from "next/link"

import { DMTooltip } from "../DMTooltip"

import { cva } from "class-variance-authority"

interface BreadcrumbItemWithLink {
  text: string
  href: string
}

export interface DMBreadcrumbsProps {
  items: (string | BreadcrumbItemWithLink)[] | undefined
  completeItems?: string[]
  noItemsText?: string
  size?: "small" | "medium"
}

const breadcrumbVariants = cva(
  ["text-neutral-faded truncate font-semibold [&>li]:inline"],
  {
    variants: {
      size: {
        small: "text-body-small",
        medium: "text-body",
      },
    },
  }
)

export function DMBreadcrumbs(props: DMBreadcrumbsProps) {
  const { completeItems, items, noItemsText, size = "small" } = props

  if (!items || items.length === 0) {
    return (
      <div className={breadcrumbVariants({ size })}>
        {noItemsText || "Root"}
      </div>
    )
  }

  const breadcrumbItems = items.map((item, index) => {
    const content =
      typeof item === "string" ? (
        <li key={item} className="">
          {item}
        </li>
      ) : (
        <Link
          href={item.href}
          key={item.text}
          className="inline-block underline hover:underline"
        >
          <li className="">{item.text}</li>
        </Link>
      )

    return (
      <>
        {content}
        {index < items.length - 1 && <li> / </li>}
      </>
    )
  })

  if (items.join(" / ").length > 40) {
    return (
      <DMTooltip
        align="start"
        text={(
          completeItems ||
          items.map((item) => {
            if (typeof item === "string") return item
            return item.text
          })
        ).join(" / ")}
        textAlign="left"
      >
        <ul className={breadcrumbVariants({ size })}>{breadcrumbItems}</ul>
      </DMTooltip>
    )
  }

  return <ul className={breadcrumbVariants({ size })}>{breadcrumbItems}</ul>
}

DMBreadcrumbs.displayName = "DMBreadcrumbs"
