"use client"

import * as React from "react"
import { FieldPath, FieldValues, useFormContext } from "react-hook-form"

import {
  DMField,
  DMFormErrorMessage,
  DMInlineTextEdit,
  DMInlineTextEditProps,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@supernovaio/dm"

/**
 * @deprecated You can safely use this component for now, but it needs to be reviewed.
 */
function DMFormInlineTextEdit<T extends FieldValues>(
  props: Omit<DMInlineTextEditProps, "name" | "hasError"> & {
    label?: string
    name: FieldPath<T>
    infoTooltip?: string
    hasOptionalBadge?: boolean
  }
) {
  const { label, name, hasOptionalBadge, infoTooltip, ...restProps } = props
  const { control } = useFormContext<T>()

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <DMField>
            {label && (
              <FormLabel
                hasOptionalBadge={hasOptionalBadge}
                infoTooltip={infoTooltip}
                text={label}
              />
            )}
            <FormControl>
              <DMInlineTextEdit {...restProps} {...field} name={name} />
            </FormControl>
            <DMFormErrorMessage size="medium" />
          </DMField>
        </FormItem>
      )}
    />
  )
}

export { DMFormInlineTextEdit }
