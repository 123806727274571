// This needs to exist because otherwise Tailwind doesn't include the classes in the build if they're only dynamically generated
import { DMSpacing } from "../../types/spacing"

type AccordingSpacing = {
  [key in DMSpacing]: string | null
}

export const accordionBleedSpacing: AccordingSpacing = {
  0: null,
  4: "[&>.accordion-item]:-mx-4 [&_.accordion-content]:px-4 [&_.accordion-header]:px-4",
  8: "[&>.accordion-item]:-mx-8 [&_.accordion-content]:px-8 [&_.accordion-header]:px-8",
  12: "[&>.accordion-item]:-mx-12 [&_.accordion-content]:px-12 [&_.accordion-header]:px-12",
  16: "[&>.accordion-item]:-mx-16 [&_.accordion-content]:px-16 [&_.accordion-header]:px-16",
  20: "[&>.accordion-item]:-mx-20 [&_.accordion-content]:px-20 [&_.accordion-header]:px-20",
  24: "[&>.accordion-item]:-mx-24 [&_.accordion-content]:px-24 [&_.accordion-header]:px-24",
  28: "[&>.accordion-item]:-mx-28 [&_.accordion-content]:px-28 [&_.accordion-header]:px-28",
  32: "[&>.accordion-item]:-mx-32 [&_.accordion-content]:px-32 [&_.accordion-header]:px-32",
  36: "[&>.accordion-item]:-mx-36 [&_.accordion-content]:px-36 [&_.accordion-header]:px-36",
  40: "[&>.accordion-item]:-mx-40 [&_.accordion-content]:px-40 [&_.accordion-header]:px-40",
}
