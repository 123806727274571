import React, { ReactNode } from "react"

import { cn } from "../../utils/cn"

import { DMListboxSelectedValue } from "./DMListbox"
import { DMListboxGridRow } from "./DMListboxGrid"
import { DMListboxGridItem } from "./DMListboxGridItem"
import { checkSelectionLimit, Item } from "./utils"

export function DMGridRow({
  items,
  rowIndex,
  forceBottomMargin = false,
  itemTitleStartSlot,
  max,
  value,
}: {
  items?: Item[]
  rowIndex?: number
  forceBottomMargin?: boolean // needed for virtualized items
  itemTitleStartSlot?: ReactNode
  max?: number
  value?: DMListboxSelectedValue
}): JSX.Element {
  return (
    <DMListboxGridRow className={cn(forceBottomMargin && "mb-12")}>
      {items?.map((item: Item) => {
        const shouldShowCheckmark =
          !max ||
          max === 1 ||
          !value ||
          !item.id ||
          !checkSelectionLimit(item.id, max, value)

        return (
          <DMListboxGridItem
            key={item.id}
            rowId={`row-${rowIndex}`}
            data-row-id={`row-${rowIndex}`}
            value={item.id}
            title={item.name}
            image={item.icon as React.ReactNode}
            titleStartSlot={itemTitleStartSlot}
            isDisabled={!shouldShowCheckmark}
            shouldShowCheckmark={shouldShowCheckmark}
          />
        )
      })}
    </DMListboxGridRow>
  )
}
