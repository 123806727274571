"use client"

import * as React from "react"
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
} from "react-hook-form"

import {
  DMErrorMessageProps,
  DMFormLabel,
  DMFormLabelProps,
} from "@supernovaio/dm"
import { DMErrorMessage } from "@supernovaio/dm/src/components/DMErrorMessage"
import { cn } from "@supernovaio/dm/src/utils/cn"

import * as LabelPrimitive from "@radix-ui/react-label"
import { Slot } from "@radix-ui/react-slot"

const Form = FormProvider

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName
}

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
)

function FormField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ ...props }: ControllerProps<TFieldValues, TName>) {
  const memoizedName = React.useMemo(() => ({ name: props.name }), [props.name])

  return (
    <FormFieldContext.Provider value={memoizedName}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  )
}

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue
)

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext)
  const itemContext = React.useContext(FormItemContext)
  const { getFieldState, formState } = useFormContext()
  const fieldState = getFieldState(fieldContext.name, formState)

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>")
  }

  const { id } = itemContext

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  }
}

type FormItemContextValue = {
  id: string
}

// TODO: Refactor disableSpacing prop
const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    disableSpacing?: boolean
    isFullWidth?: boolean
  }
>(({ className, disableSpacing, isFullWidth = true, ...props }, ref) => {
  const id = React.useId()
  const memoizedId = React.useMemo(() => ({ id }), [id])

  return (
    <FormItemContext.Provider value={memoizedId}>
      <div
        ref={ref}
        className={cn(
          { "w-full": isFullWidth },
          { "flex-1 space-y-2": !disableSpacing },
          className
        )}
        {...props}
      />
    </FormItemContext.Provider>
  )
})

FormItem.displayName = "FormItem"

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  DMFormLabelProps
>((props, ref) => {
  const { formItemId } = useFormField()

  return <DMFormLabel ref={ref} htmlFor={formItemId} {...props} />
})

FormLabel.displayName = "FormLabel"

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

  return (
    <Slot
      ref={ref}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      id={formItemId}
      {...props}
    />
  )
})

FormControl.displayName = "FormControl"

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField()

  return (
    <p
      ref={ref}
      className={cn("text-muted-foreground text-sm", className)}
      id={formDescriptionId}
      {...props}
    />
  )
})

FormDescription.displayName = "FormDescription"

export type DMFormErrorMessageProps = Omit<DMErrorMessageProps, "children"> & {
  children?: React.ReactNode | undefined
}

const DMFormErrorMessage = React.forwardRef<
  HTMLParagraphElement,
  DMFormErrorMessageProps
>(({ children, ...props }, ref) => {
  const { error, formMessageId } = useFormField()
  let body: string | React.ReactNode | null

  if (error?.message) {
    // Single error message is defined

    body = String(error.message)
  } else if (error) {
    // Try to obtain multiple error messages

    const joinedMessage: string = Object.values(error)
      .map((fieldError) => {
        return fieldError &&
          typeof fieldError === "object" &&
          "message" in fieldError
          ? String(fieldError.message)
          : null
      })
      .filter((e) => e !== null)
      .join("\n")

    if (joinedMessage) {
      body = joinedMessage
    }
  }

  if (!body) {
    body = children
  }

  if (!body) {
    return null
  }

  return (
    <DMErrorMessage ref={ref} id={formMessageId} {...props}>
      {body}
    </DMErrorMessage>
  )
})

DMFormErrorMessage.displayName = "DMFormErrorMessage"

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  DMFormErrorMessage,
  FormField,
}
