"use client"

import * as React from "react"

import { DMNumberInput, DMNumberInputProps } from "@supernovaio/dm"

import {
  DMUnitToggleButton,
  ToggleButtonUnitEnum,
  toggleButtonUnits,
} from "../DMUnitToggleButton"

export const dimensionUnits = toggleButtonUnits

export type DMDimensionInputValue = {
  measure: number | null
  unit: string
}

export type DMDimensionInputProps = Pick<
  DMNumberInputProps,
  | "name"
  | "className"
  | "id"
  | "step"
  | "min"
  | "max"
  | "isDisabled"
  | "isReadOnly"
  | "hasError"
  | "preventEnterSubmit"
  | "canReturnNull"
  | "placeholder"
  | "onBlur"
> & {
  value: DMDimensionInputValue | null
  onDimensionValueChange: (value: DMDimensionInputValue) => void
  allowedUnits: string[]
}

const DMDimensionInput = React.forwardRef<
  HTMLInputElement,
  DMDimensionInputProps
>(
  (
    {
      value,
      allowedUnits,
      onDimensionValueChange,
      placeholder = "null",
      ...restProps
    },
    ref
  ) => {
    if (allowedUnits.length === 0) {
      throw new Error("DMDimensionInput: allowedUnits cannot be empty")
    }

    const hasOnlyRawUnit =
      allowedUnits.length === 1 && allowedUnits[0] === ToggleButtonUnitEnum.raw

    // We need support null values to prevent crash of app
    const defaultValue = {
      measure: null,
      unit: allowedUnits[0] as string,
    } satisfies DMDimensionInputValue

    const valueRef = React.useRef<NonNullable<DMDimensionInputValue>>(
      value ?? defaultValue
    )

    const handleUnitChange = (unit: string) => {
      valueRef.current = {
        measure: valueRef.current.measure,
        unit,
      }
      onDimensionValueChange(valueRef.current)
    }

    const handleValueChange = (measure: number | null) => {
      valueRef.current = {
        measure,
        unit: valueRef.current.unit,
      }
      onDimensionValueChange(valueRef.current)
    }

    return (
      <DMNumberInput
        ref={ref}
        placeholder={placeholder}
        {...restProps}
        selectOnFocus
        endSlot={
          hasOnlyRawUnit ? undefined : (
            <DMUnitToggleButton
              allowedUnits={allowedUnits}
              selectedUnit={valueRef.current.unit}
              onUnitChange={handleUnitChange}
            />
          )
        }
        value={value?.measure ?? null}
        onValueChange={handleValueChange}
      />
    )
  }
)

DMDimensionInput.displayName = "DMDimensionInput"

export { DMDimensionInput }
