"use client"

import * as React from "react"
import { createContext, useContext, useMemo } from "react"

import {
  ComboboxList as ComboboxListPrimitive,
  ComboboxListProps as ComboboxListPropsPrimitive,
} from "@ariakit/react"

import { cn } from "../../utils/cn"

import { getGridColumnClass } from "./utils"

export interface DMListboxGridProps
  extends Omit<
    ComboboxListPropsPrimitive,
    "disabled" | "focusable" | "children"
  > {
  isDisabled?: boolean
  isFocusable?: boolean
  isVirtualized?: boolean
  height?: number
  gridColumns?: number
  children: React.ReactNode
}

export interface DMListboxGridRowProps {
  children: React.ReactNode
  className?: string
}

interface DMListboxGridContextType {
  gridColumns: number | undefined
  height?: number
}

// Context is needed to pass down some grid properties down to the Row/Item
export const DMListboxGridContext = createContext<DMListboxGridContextType>({
  gridColumns: undefined,
})

const DMListboxGrid = React.forwardRef<
  React.ElementRef<typeof ComboboxListPrimitive>,
  DMListboxGridProps
>(
  (
    {
      children,
      isDisabled,
      isFocusable,
      gridColumns = 3,
      isVirtualized = false,
      height = 460,
      className,
      ...props
    },
    ref
  ) => {
    const memoizedContextProps: DMListboxGridContextType = useMemo(
      () => ({ gridColumns, height }),
      [gridColumns, height]
    )

    return (
      <DMListboxGridContext.Provider value={memoizedContextProps}>
        <ComboboxListPrimitive
          ref={ref}
          alwaysVisible
          disabled={isDisabled}
          focusable={isFocusable}
          className={cn(isVirtualized && "h-full", className)}
          {...props}
        >
          <div
            className={cn(
              "pr-4 h-full",
              !isVirtualized &&
                "overflow-auto scrollbar-fancy scrollbar-stable grid gap-12 content-start grid-flow-col",
              getGridColumnClass(gridColumns)
            )}
          >
            {children}
          </div>
        </ComboboxListPrimitive>
      </DMListboxGridContext.Provider>
    )
  }
)

DMListboxGrid.displayName = "DMListboxGrid"

function DMListboxGridRow(props: DMListboxGridRowProps) {
  const { children, className } = props
  const { gridColumns } = useContext(DMListboxGridContext)

  if (!gridColumns) {
    throw new Error("DMListboxGridRow must be used inside DMListboxGrid")
  }

  return (
    <div
      className={cn(
        "grid col-span-full gap-12 items-stretch w-full",
        getGridColumnClass(gridColumns),
        className
      )}
    >
      {children}
    </div>
  )
}

DMListboxGridRow.displayName = "DMListboxGridRow"

export { DMListboxGrid, DMListboxGridRow }
