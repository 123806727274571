"use client"

import React, { useState } from "react"

import { DMLabel, normalizeTestId } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import * as SwitchPrimitives from "@radix-ui/react-switch"

import { getLabel, Label, SwitchProps } from "../types"

function getCommonClassNames(isLabelRight?: boolean) {
  return cn("flex grow-0 items-center gap-8", {
    "flex-row-reverse": !isLabelRight,
  })
}

type DMSwitchProps = SwitchProps & {
  isReadOnly?: false
  label?: Label | boolean
  className?: string
  dataTestId?: string
}

type ReadOnlyDMSwitchProps = SwitchProps & {
  isReadOnly: true
  label: Label | true
  dataTestId?: string
}

const DMSwitch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  DMSwitchProps | ReadOnlyDMSwitchProps
>((props, ref) => {
  const { isReadOnly, label, isChecked: checked, dataTestId } = props
  const [isChecked, setIsChecked] = useState<boolean>(Boolean(checked))
  const actualChecked = props.isChecked ?? isChecked

  if (isReadOnly && "isReadOnly" in props) {
    return (
      <DMLabel className="text-body-small cursor-text font-semibold">
        {getLabel({ isChecked, label })}
      </DMLabel>
    )
  }

  const {
    className,
    id,
    isLabelRight,
    onChange,
    isDisabled,
    size = "small",
  } = props

  const handleOnCheckedChange = (changed: boolean) => {
    setIsChecked(changed)
    onChange?.(changed)
  }

  return (
    <div className={getCommonClassNames(isLabelRight)}>
      <SwitchPrimitives.Root
        ref={ref}
        checked={actualChecked}
        className={cn(
          "inline-flex cursor-pointer items-center rounded-[999px] transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 disabled:cursor-not-allowed",
          "data-[state=checked]:bg-primary",
          "data-[state=unchecked]:bg-[--rs-color-foreground-disabled]",
          "data-[state=unchecked]:hover:bg-[--rs-color-foreground-neutral-placeholder]",
          "data-[disabled]:data-[state=checked]:bg-disabled",
          "data-[disabled]:data-[state=checked]:hover:bg-disabled",
          "data-[disabled]:data-[state=unchecked]:bg-disabled",
          "data-[disabled]:data-[state=unchecked]:hover:bg-disabled",
          {
            "h-[20px] w-[32px]": size === "medium",
            "h-[16px] w-[28px]": size === "small",
          },
          className
        )}
        disabled={isDisabled}
        data-test-id={normalizeTestId(dataTestId)}
        id={id}
        onCheckedChange={handleOnCheckedChange}
      >
        <SwitchPrimitives.Thumb
          className={cn(
            "rounded-[999px] bg-white transition-transform",
            "data-[disabled]:shadow-raised",
            "data-[state=checked]:translate-x-[14px]",
            "data-[state=unchecked]:translate-x-[2px]",
            {
              "h-icon-small w-icon-small": size === "medium",
              "h-[12px] w-[12px]": size === "small",
            }
          )}
        />
      </SwitchPrimitives.Root>
      {label && (
        <DMLabel
          className={cn(
            "text-body-small font-semibold",
            !!isDisabled && "text-disabled cursor-not-allowed"
          )}
          htmlFor={id}
        >
          {getLabel({ isChecked: actualChecked, label })}
        </DMLabel>
      )}
    </div>
  )
})

DMSwitch.displayName = SwitchPrimitives.Root.displayName

export { DMSwitch }
