import * as React from "react"
import { useContext } from "react"

import {
  ComboboxGroup as ComboboxGroupPrimitive,
  ComboboxGroupProps as ComboboxGroupPropsPrimitive,
  ComboboxGroupLabel as ComboboxGroupLabelPrimitive,
} from "@ariakit/react"

import { cn } from "../../utils/cn"

import { DMListboxGridContext } from "./DMListboxGrid"
import { getGridColumnClass } from "./utils"

export interface DMListboxGroupProps
  extends Omit<ComboboxGroupPropsPrimitive, "children"> {
  label?: string
  children: React.ReactNode
}

export type DMListboxGroupHeadingProps = {
  title: string | undefined
  startSlot?: React.ReactNode
}

const DMListboxGroup = React.forwardRef<
  React.ElementRef<typeof ComboboxGroupPrimitive>,
  DMListboxGroupProps
>(({ label, children, ...props }, ref) => {
  const { gridColumns } = useContext(DMListboxGridContext)

  return (
    <ComboboxGroupPrimitive
      ref={ref}
      {...props}
      className={cn(
        "mb-12 last:mb-0 col-span-full",
        gridColumns && "col-span-full",
        props.className
      )}
    >
      <ComboboxGroupLabelPrimitive className="p-4 text-neutral-faded font-semibold text-body-small col-span-full sticky top-0 bg-blur z-10">
        {label}
      </ComboboxGroupLabelPrimitive>
      {gridColumns ? (
        <div
          className={cn(
            "grid gap-12 items-stretch",
            getGridColumnClass(gridColumns)
          )}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </ComboboxGroupPrimitive>
  )
})

/*
  Heading for virtualized groups
 */

function DMListboxGroupHeading(props: DMListboxGroupHeadingProps) {
  const { title, startSlot } = props

  return (
    <div className="p-4 pt-12 bg-blur text-neutral-faded font-semibold text-body-small flex gap-8">
      {startSlot}
      {title}
    </div>
  )
}

DMListboxGroupHeading.displayName = "DMListboxGroupHeading"
DMListboxGroup.displayName = "DMListboxGroup"

export { DMListboxGroup, DMListboxGroupHeading }
