"use client"

import * as React from "react"
import { FieldPath, FieldValues, useFormContext } from "react-hook-form"

import {
  DMField,
  DMFormErrorMessage,
  DMTextInput,
  DMTextInputProps,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@supernovaio/dm"

// TODO: Refactor disableSpacing prop
type Props<T extends FieldValues> = Omit<
  DMTextInputProps,
  "name" | "hasError" | "onChange" | "value" | "ref" | "onBlur"
> & {
  label?: string
  name: FieldPath<T>
  infoTooltip?: string
  hasOptionalBadge?: boolean
  onFormFieldBlur?: () => void
  disableSpacing?: boolean
}

function DMFormTextFieldWithoutRef<T extends FieldValues>(
  props: Props<T>,
  forwardedRef: React.Ref<HTMLInputElement | null>
) {
  const {
    infoTooltip,
    hasOptionalBadge = false,
    label,
    name,
    size = "medium",
    onFormFieldBlur,
    disableSpacing,
    ...restProps
  } = props

  const {
    formState: { errors },
    control,
  } = useFormContext<FieldValues>()

  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { ref, ...field } }) => (
        <FormItem disableSpacing={disableSpacing}>
          <DMField>
            {label && (
              <FormLabel
                hasOptionalBadge={hasOptionalBadge}
                infoTooltip={infoTooltip}
                text={label}
              />
            )}
            <FormControl onBlur={onFormFieldBlur}>
              <DMTextInput
                ref={(instance) => {
                  if (forwardedRef) {
                    if (typeof forwardedRef === "function") {
                      forwardedRef(instance)
                    } else {
                      ;(
                        forwardedRef as React.MutableRefObject<HTMLInputElement | null>
                      ).current = instance
                    }
                  }
                  ref(instance)
                }}
                hasError={!!errors[name]}
                {...restProps}
                {...field}
                name={name}
                size={size}
              />
            </FormControl>
            <DMFormErrorMessage size={size} />
          </DMField>
        </FormItem>
      )}
    />
  )
}

export const DMFormTextField = React.forwardRef(DMFormTextFieldWithoutRef)
DMFormTextField.displayName = "DMFormTextField"
