import React from "react"

import { DMIcon, DMLabel, DMTooltip } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconInfo } from "@supernovaio/icons"

import * as LabelPrimitive from "@radix-ui/react-label"
import { LabelProps } from "@radix-ui/react-label"

export type DMFormLabelProps = Pick<
  React.HTMLAttributes<HTMLDivElement>,
  "className"
> &
  Pick<LabelProps, "htmlFor"> & {
    text: string
    infoTooltip?: string
    hasOptionalBadge?: boolean
    endSlot?: React.ReactNode
  }

const DMFormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  DMFormLabelProps
>((props, ref) => {
  const {
    text,
    infoTooltip,
    hasOptionalBadge = false,
    className,
    endSlot,
    ...restProps
  } = props

  return (
    <div className={cn("flex w-full flex-row items-center gap-x-4", className)}>
      <DMLabel
        ref={ref}
        className="text-body text-neutral inline-block max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap font-semibold"
        {...restProps}
      >
        {text}
      </DMLabel>
      {infoTooltip && (
        <DMTooltip text={infoTooltip}>
          <DMIcon color="neutral-faded" size="small" svg={<IconInfo />} />
        </DMTooltip>
      )}
      {hasOptionalBadge && (
        <div className="ml-auto">
          <span className="text-body text-neutral-faded font-normal">
            Optional
          </span>
        </div>
      )}
      {endSlot}
    </div>
  )
})

DMFormLabel.displayName = "DMFormLabel"

export { DMFormLabel }
