import * as React from "react"
import { ReactNode } from "react"

import {
  ComboboxItemProps as ComboboxItemPropsPrimitive,
  ComboboxItem as ComboboxItemPrimitive,
  useComboboxContext,
} from "@ariakit/react"

import { cn } from "../../utils/cn"

import { ListboxCheckmarkIcon } from "./utils"

export interface DMListboxGridItemProps
  extends Omit<
    ComboboxItemPropsPrimitive,
    "disabled" | "focusable" | "tabbable"
  > {
  isDisabled?: boolean
  isFocusable?: boolean
  isTabbable?: boolean
  title: string
  description?: string
  image?: ReactNode
  titleStartSlot?: ReactNode
  shouldShowCheckmark?: boolean
}

const DMListboxGridItem = React.forwardRef<
  React.ElementRef<typeof ComboboxItemPrimitive>,
  DMListboxGridItemProps
>(
  (
    {
      value,
      title,
      image,
      description,
      isDisabled,
      isFocusable,
      isTabbable,
      titleStartSlot,
      shouldShowCheckmark = true,
      ...props
    },
    ref
  ) => {
    const combobox = useComboboxContext()
    const isMultiselect = combobox?.useState((state) =>
      Array.isArray(state.selectedValue)
    )
    const isSelected = combobox?.useState((state) => {
      if (!value) return false

      return isMultiselect
        ? state.selectedValue.includes(value)
        : state.selectedValue === value
    })

    return (
      <ComboboxItemPrimitive
        ref={ref}
        focusOnHover
        disabled={isDisabled}
        focusable={isFocusable}
        tabbable={isTabbable}
        hideOnClick={false}
        setValueOnClick={false}
        {...props}
        value={value}
        id={value}
        className={cn(
          "grid-item",
          "flex flex-col inner-border inner-border-neutral-faded rounded relative",
          "data-[active-item]:bg-primary-faded data-[active-item]:inner-border-primary-faded",
          "scroll-mt-[48px]", // for correct behavior with sticky group labels + virtualization keyboard navigation
          isSelected && "!inner-border-primary inner-border-2",
          props.className
        )}
      >
        {image && (
          <div className="w-full bg-neutral-faded h-[120px] flex items-center justify-center text-neutral-faded p-8">
            {image}
          </div>
        )}

        <div className="font-semibold text-body-small text-neutral flex flex-col gap-2 p-8 break-words">
          <div className="flex gap-4">
            {titleStartSlot}
            {title}
          </div>
          <div className="font-normal text-body-small text-neutral-faded">
            {description}
          </div>
        </div>
        {shouldShowCheckmark && (
          <div className="absolute top-8 right-8 z-1">
            <ListboxCheckmarkIcon
              isSelectable
              isSelected={isSelected}
              isMultiselect={isMultiselect}
            />
          </div>
        )}
      </ComboboxItemPrimitive>
    )
  }
)

DMListboxGridItem.displayName = "DMListboxGridItem"

export { DMListboxGridItem }
